@import "clearfix";
@import "media-queries";
@import "retina";
@import "sprite";

@import "normalize";
@import "bootstrap";

@import "swiper";

@font-face {
	font-family: 'HarmoniaSansProCyr';
	src: url("/templates/administrative_director/fonts/HarmoniaSansProCyr-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HarmoniaSansProCyr';
	src: url("/templates/administrative_director/fonts/HarmoniaSansProCyr-SemiBd.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'HarmoniaSansProCyr';
	src: url("/templates/administrative_director/fonts/HarmoniaSansProCyr-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
}

* {
	&,
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

html {
	height: 100%;
}

html,
body {
	font-size: 16px;
}

body {
	display: flex;
	flex-direction: column;
	min-width: 280px;
	min-height: 100%;
	background-color: #fff;
	color: #000;
	font-size: 16px;
	line-height: 1.25; // ~20px
	font-family: 'HarmoniaSansProCyr', sans-serif;

	@media screen and (max-width:960px) {
		transform: translateX(0);
		transition: transform 0.35s;

		&.navigation-visible {
			overflow: hidden;
			transform: translateX(-250px);
		}
	}
}

a {
	border-bottom: 1px solid transparent;
	color: #78c479;
	text-decoration: none;

	&:hover {
		border-color: rgba(120,196,121,0.25);
		color: #78c479;
	}
}

h1 {
	font-weight: bold;
	font-size: 36px;
	line-height: 1.2; // ~36px

	@media screen and (max-width: 720px) {
		font-size: 28px;
	}
}

h2 {
	font-weight: 600;
	font-size: 24px;
	line-height: 1.25; // ~30px

	@media screen and (max-width: 720px) {
		font-size: 21px;
		line-height: 1.28;
	}
}

h3 {
	font-weight: 600;
	font-size: 18px;
	line-height: 1.3; // ~24px

	@media screen and (max-width: 720px) {
		font-size: 16px;
		line-height: 1.4;
	}
}


// LAYOUT
.container {
	margin-right: auto;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 20px;
	width: 100%;
	max-width: 1220px;
}


// LOGO
.logo {
	border: none;

	.header & {
		width: 100%;
		max-width: 280px;
		height: auto;

		@media screen and (max-width:480px) {
			margin-right: 32px;
		}
	}

	.footer & {
		float: right;
		margin: 5px 0;
		width: 142px;
		height: 47px;

		@media screen and (max-width:720px) {
			display: none;
		}
	}
}

	.logo-img {
		max-width: 100%;
		height: auto;
		vertical-align: middle;
	}


// NAVIGATION
.navigation {
	display: flex;

	.header & {
		justify-content: center;
		align-items: flex-end;

		@media screen and (max-width:960px) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			position: fixed;
			z-index: +100;
			top: 0;
			left: 100%;
			padding: 25px 0 5px 0;
			width: 250px;
			height: 120vh;
			background-color: #f6f6f6;
		}
	}

	.footer & {
		@media screen and (max-width:640px) {
			flex-wrap: wrap;
		}
	}

	a {
		.header & {
			margin: 0 20px;
			padding: 40px 0 36px;
			border-bottom-width: 4px;
			color: #000;
			font-weight: bold;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			@media screen and (max-width:960px) {
				margin: 5px 0;
			    padding: 5px 10px 5px 30px;
			    width: 100%;
			    border-left: 4px solid transparent;
				border-bottom: none;
			}

			&.active,
			&:hover {
				border-color: #78c479;
				color: #78c479;
			}
		}

		.header-on-top & {
			color: #fff;

			@media screen and (max-width:960px) {
				color: #000;
			}
		}

		.footer & {
			margin-right: 40px;
			border-color: rgba(255,255,255,0.25);
			color: #fff;

			@media screen and (max-width:640px) {
				display: block;
				width: 100%;
				margin-right: 0;
				padding: 10px 0;
				border: none;
			}

			&.active,
			&:hover {
				border-color: rgba(120,196,121,0.25);
				color: #78c479;
			}
		}
	}
}
.navigation-toggle {
	@media screen and (min-width:961px) {
		display: none;
	}

	@media screen and (max-width:960px) {
		position: relative;
		overflow: hidden;
		padding: 0;
		margin: 0;
		border: none;
		border-radius: 0;
		outline: none;
		background-color: transparent !important;
		width: 32px;
		height: 32px;
		text-indent: -9999px;

		&:not(.navigation-toggle-cross) {
			&:before {
				content: '';
				position: absolute;
				top: 9px;
				left: 5px;
				width: 22px;
				height: 14px;
				border-top: 2px solid #000;
				border-bottom: 2px solid #000;

				.header-on-top & {
					border-color: #fff;
				}
			}

			&:after {
				content: '';
				position: absolute;
				top: 15px;
				left: 5px;
				width: 22px;
				height: 2px;
				background-color: #000;

				.header-on-top & {
					background-color: #fff;
				}
			}
		}
	}
}

.navigation-toggle-cross {
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 15px;
		left: 5px;
		width: 22px;
		height: 2px;
		background-color: #000;

		.header-on-top & {
			background-color: #fff;
		}
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
}


// HEADER
.header {
	width: 100%;
	height: 100px;
	border-bottom: 1px solid rgba(0,0,0,0.15);

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}
}

.header-on-top {
	position: relative;
	z-index: +10;
	height: 150px;
	border-bottom: none;
}

	//  lang
	.lang-header {
		position: relative;
		top: 1px;
		color: #000;
		font-weight: bold;

		@media screen and (max-width: 960px) {
			display: none;
		}

		.header-on-top & {
			color: #fff;

			&:hover,
			&:focus {
				color: #78c479;
			}
		}
	}

	.lang-nav {
		margin-top: 25px !important;

		@media screen and (min-width: 961px) {
			display: none;
		}
	}


// COLS
.cols {
	@media screen and (min-width: 961px) {
		@include clearfix();
		margin-right: -10px;
		margin-left: -10px;
	}

	main,
	.main,
	aside,
	.aside {
		@media screen and (min-width: 961px) {
			float: left;
			padding-right: 10px;
			padding-left: 10px;
			min-height: 1px;
		}
	}

	main,
	.main {
		width: 75%;

		@media screen and (max-width: 960px) {
			width: 100%;
		}
	}

	aside,
	.aside {
		width: 25%;

		@media screen and (max-width: 960px) {
			margin-top: 50px;
			width: 100%;
		}
	}
}


// COLS
.cells {
	margin-top: -60px;
	display: flex;
	flex-wrap: wrap;
}

.cell {
	.cells & {
		margin-top: 60px;
		width: 100%;

		@media screen and (min-width: 640px) {
			width: 50%;
		}

		@media screen and (min-width: 768px) {
			width: 33.33333%;
		}

		@media screen and (min-width: 960px) {
			width: 25%;
		}
	}
}


// CONTENT
.content {
	padding-top: 25px;
	padding-bottom: 50px;
}

	// title
	.title {
		margin-top: 25px;
		margin-bottom: 10px;
	}

	// lead
	.lead {
		margin-top: -5px;
		margin-bottom: 0;
		font-weight: 600;
		font-size: 20px;
	}


// BREADCRUMBS
.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	margin-top: 40px;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	font-weight: 600;

	li {
		position: relative;
		margin-right: 45px;

		&:not(:last-child):after {
			content: '';
			position: absolute;
			top: calc(50% - 5px);
			left: calc(100% + 20px);
			width: 5px;
			height: 9px;
			background: transparent url('/templates/administrative_director/images/arr-dark-right.svg') no-repeat 0 0;
		}
	}
}


// ARTICLE
.article {
	header {
		time {
			display: block;
			margin-top: 10px;
			color: #737373;
			font-weight: bold;
			font-size: 14px;
		}
	}

	footer {
		margin-top: 20px;
	}

	.title {
		margin-top: 0;
		margin-bottom: 0;
	}

	.lead {
		margin-top: 5px;
	}

	p,
	ul,
	ol,
	blockquote {
		margin-top: 1.25em;
		margin-bottom: 0;
		line-height: 1.6;
	}
}

	// social
	.socail {
		display: block;
		max-width: 100%;
	}

	// back
	.back {
		display: inline-block;
		margin-top: 40px;
		padding: 10px 20px 8px 30px;
		border: 1px solid rgba(0,0,0,0.15);
		border-radius: 2px;
		background: transparent url('/templates/administrative_director/images/arr-dark-left.png') no-repeat left 15px top 14px;
		// background-size: 5px auto;
		color: #033333;

		&:hover {
			border-color: #78c479;
			background-color: #78c479;
			background-image: url('/templates/administrative_director/images/arr-light-left.png');
			color: #fff;
		}
	}


// ARTICLE LIST
.article-list {
	header {
		h2 {
			margin-top: 10px;
			margin-bottom: 10px;
			font-weight: 600;
			font-size: 18px;
		}

		time {
			margin-top: 0;
		}
	}

	p,
	ul,
	ol,
	blockquote {
		margin-top: 0.625em; // ~10px
		margin-bottom: 0;
		line-height: 1.6;
	}

	.article {
		padding-bottom: 20px;
		margin-bottom: 25px;
		border-bottom: 1px solid rgba(0,0,0,0.15);
	}

	footer {
		a {
			font-weight: 600;
		}
	}
}


// CARD
// .cards {
// 	display: flex;
// 	flex-wrap: wrap;
// 	margin: 0 -10px;

// 	@media screen and (max-width: 960px) {
// 		margin-top: -20px;
// 	}

// 	@media screen and (max-width: 480px) {
// 		margin-right: 0;
// 		margin-left: 0;
// 	}
// }

.card {
	position: relative;
	margin-bottom: 10px;
	padding: 30px 20px;
	border: 1px solid rgba(0,0,0,0.1);
	background-color: #fff;

	&:before,
	&:after {
		content: '';
		position: absolute;
		height: 4px;
		border: 1px solid rgba(0,0,0,0.1);
		border-top: none;
		background-color: #fff;
	}

	&:before {
		top: calc(100% + 1px);
		left: 6px;
		right: 6px;
	}

	&:after {
		top: calc(100% + 5px);
		left: 16px;
		right: 12px;
	}

	// .cards & {
	// 	margin-right: 10px;
	// 	margin-left: 10px;
	// 	width: calc(25% - 20px);

	// 	@media screen and (max-width: 960px) {
	// 		margin-top: 20px;
	// 	}

	// 	@media screen and (min-width: 481px) and (max-width: 960px) {
	// 		width: calc(50% - 20px);
	// 	}

	// 	@media screen and (max-width: 480px) {
	// 		margin-right: 0;
	// 		margin-left: 0;
	// 		width: 100%;
	// 	}
	// }

	time {
		display: inline-block;
		margin-bottom: 0.5em;
		color: #737373;
		font-weight: 600;
		font-size: 14px;
	}

	h3 {
		margin-top: 0;
		margin-bottom: 0;
		font-weight: 600;
	}

	p {
		margin-top: 1em;
		margin-bottom: 0;
	}
}


// PAGES
.pages {
	display: flex;
	margin-top: 35px;

	a {
		padding: 10px 0 9px;
		width: 38px;
		border: none;
		border-radius: 50%;
		color: #000;
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;
		text-align: center;

		&.active {
			background-color: #78c479;
			color: #fff;
		}
	}

	.pages-prev {
		margin-right: 15px;
		width: auto;
	}

	.pages-next {
		margin-left: 15px;
		width: auto;
	}
}


// PANEL
.panel {
	border: 1px solid rgba(0,0,0,0.15);

	h2,
	h3 {
		margin-top: 0;
		margin-bottom: 0;
		border-bottom: 1px solid rgba(0,0,0,0.15);
	}

	h2 {
		padding: 23px 35px;
	}

	h3 {
		padding: 15px 20px;
	}
}

.panel-pushed {
	margin-top: 83px; // высота шапки статьи

	@media screen and (max-width:960px) {
		margin-top: 0;
	}
}

	.panel-body {
		padding: 20px;

		.contacts-action & {
			padding: 25px 35px;
		}
	}


// SUBSCRIBE
.subscribe {
	@media screen and (max-width: 960px) {
		margin-right: auto;
		margin-left: auto;
		max-width: 480px;
	}
}


// FORM
form {
	[type="submit"] {
		margin-top: 15px;

		.contacts-action & {
			margin-top: 25px;
		}
	}
}

button,
.button {
	display: inline-block;
	margin: 0;
	padding: 10px 20px;
	border: none;
	border-radius: 2px;
	outline: none;
	background-color: #78c479;
	color: #fff !important;
	font-weight: bold;
	line-height: 20px;
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: #85d486;
	}

	&.large {
		padding-top: 15px;
		padding-bottom: 15px;
		font-size: 18px;
	}

	.subscribe & {
		padding-right: 5px;
		padding-left: 5px;
		width: 100%;
		text-align: center;
	}
}

.field {
	margin-top: 0;
	margin-bottom: 0;

	& + & {
		margin-top: 30px;
	}

	label {
		display: none;
	}

	input,
	textarea {
		display: block;
		margin: 0;
		padding: 9px 1px;
		width: 100%;
		height: 40px;
		border: none;
		border-bottom: 2px solid rgba(0,0,0,0.1);
		border-radius: 0;
		outline: none;
		background-color: transparent;

		&:focus {
			border-color: #78c479;
		}
	}

	textarea {
		resize: none;
	}
}


// CONTACTS
.contacts {}

	// contacts list
	.contacts-list {
		margin-top: 0;
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
		line-height: 1.6;

		li {
			&:not(:first-child) {
				margin-top: 1em;
			}
		}
	}

	// contacts actions
	.contacts-action {
		margin: 40px -10px 0 -10px;
		display: flex;
		align-items: stretch;

		@media screen and (max-width: 960px) {
			flex-wrap: wrap;
		}
	}

		.contacts-map,
		.contacts-form {
			padding: 0 10px;
			width: 50%;

			@media screen and (max-width: 960px) {
				width: 100%;
				height: 500px;
			}
		}

		.contacts-form {
			@media screen and (max-width: 960px) {
				margin-top: 40px;
			}
		}

			.map-container {
				position: relative;
				width: 100%;
				height: 100%;
				background-color: #eee;

				&:before {
					content: attr(data-map-loading);
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}


// STANDART
.standart {
	width: 100%;
	max-width: 228px;
	height: auto;

	@media screen and (max-width: 960px) {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}
}


// PERSONA
.persona {
	padding-right: 20px;
	// width: 25%;

	// .carousel & {
	// 	float: left;
	// }

	img {
		display: block;
		margin: 0 auto 35px;
		border-radius: 50%;
	}

	h3 {
		margin-top: 0;
		margin-bottom: 0;
		font-weight: bold;
	    font-size: 22px;
	    line-height: 1.25;
	}

	p {
		margin-bottom: 0;

		&:not(.persona-position) {
			margin-top: 20px;
			font-size: 14px;
		}

		&.persona-position {
			margin-top: 5px;
			font-weight: 600;
			font-size: 14px;
			line-height: 1.5;
		}
	}
}

	.persona-social {
		display: flex;
		margin-top: 10px;

		a {
			margin: 10px 10px 0 0;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: center center;
		}

		.facebook { background-image: url('/templates/administrative_director/images/facebook.png'); }
		.linkedin { background-image: url('/templates/administrative_director/images/linkedin.png'); }
		.twitter { background-image: url('/templates/administrative_director/images/twitter.png'); }
	}


// USER
.users {}

.user {
	margin-right: auto;
	margin-left: auto;
	max-width: 480px;

	.users & {
		position: relative;
		display: block;
		padding: 15px 10px 15px;
		min-height: 80px;
		border-color: rgba(0,0,0,0.1);
		font-weight: bold;
		font-size: 0;

		&:hover {
			border-bottom-color: #78c479;
		}
	}
}

.user_current {
	margin-bottom: 30px;
	text-align: center;
}

	.user__pic {
		border-radius: 50%;

		.user_current & {
			margin-bottom: 45px;
			width: 168px;
			height: 168px;
			vertical-align: middle;
		}

		.users & {
			display: inline-block;
			margin-right: 20px;
			width: 50px;
			height: 50px;
			vertical-align: middle;
		}
	}

	.user__name {
		.users & {
			display: inline-block;
			width: calc(100% - 70px);
			color: #000;
			font-size: 18px;
			vertical-align: middle;
		}
		.users .user:hover & {
			color: #78c479;
		}
	}

	.user__quote {
		margin: 0;
		padding: 20px 30px;
		background-color: #f3f3f1;
		font-weight: bold;
		font-style: italic;
		text-align: center;

		&:before {
			content: '«';
		}
		&:after {
			content: '»';
		}
	}


// BENEFITS
.benefits {
	margin-top: 65px;

	h3 {
		margin-top: 0;
		margin-bottom: 0;
		font-weight: 600;
		font-size: 30px;
		line-height: 1.2; // ~36px
	}

	h4 {
		margin-top: 0;
		margin-bottom: 0;
	    font-weight: 600;
	    font-size: 18px;
	    line-height: 1.3;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		margin-top: 0;
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
	}

	li {
		position: relative;
		margin-top: 45px;
		padding: 0 20px 0 90px;
		width: 50%;

		@media screen and (max-width: 720px) {
			width: 100%;
		}

		@media screen and (max-width: 480px) {
			padding-top: 90px;
			padding-left: 0;
		}
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 62px;
		height: 62px;
	}

	p {
		margin-top: 10px;
		margin-bottom: 0;
	}
}


// BENEFITS
.services {}

	.service {
		padding-top: 50px;
		padding-bottom: 65px;
		background-color: #f3f3f1;

		.container {
			display: flex;
			align-items: center;

			@media screen and (max-width: 768px) {
				flex-wrap: wrap;
			}
		}
	}

	.service_revese {
		background-color: #fff;
	}

	.service__pic {
		order: 1;
		padding: 0 30px;
		width: 33.33333%;
		text-align: center;

		.service_revese & {
			order: 2;
		}

		@media screen and (max-width: 768px) {
			margin-bottom: 40px;
			order: 1 !important;
			width: 100%;
		}
	}

		.service__img {
			width: 100%;
			max-width: 380px;
			height: auto;
			vertical-align: middle;

			@media screen and (max-width: 768px) {
				max-width: 280px;
			}
		}

	.service__content {
		order: 2;
		padding: 0 15px;
		width: 66.66666%;
		line-height: 1.6;

		.service_revese & {
			order: 1;
		}

		@media screen and (max-width: 768px) {
			order: 2 !important;
			width: 100%;
		}

		h2 {
			margin-top: 0;
			margin-bottom: 1em;
		}

		ul {
			padding-left: 0;
			list-style: none;
			font-size: 14px;

			li {
				position: relative;
				margin-top: 10px;
				padding-left: 38px;

				&:before {
					content: '';
					position: absolute;
					top: 10px;
					left: 0;
					width: 20px;
					height: 1px;
					background-color: rgba(0,0,0,0.8);
				}
			}
		}
	}


// GRAPH
.graph {}


// LEVEL
.level {}

.level-cover {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	margin-top: -150px; // header height
	padding-top: 150px; // header height
	padding-bottom: 75px;
	background: #777 url('/templates/administrative_director/images/cover.jpg') no-repeat center center;
	background-size: cover;
	color: #fff;

	@media screen and (min-width: 720px) {
		min-height: 640px;
		height: 100vh;
	}

	.container {
		position: relative;
		z-index: 2;
	}

	h1,
	p {
		max-width: 700px;
	}

	h1 {
		margin-top: 0;
		margin-bottom: 0;
		font-weight: bold;
	    font-size: 36px;
	    line-height: 1.25;

	    @media screen and (max-width: 768px) {
			font-size: 28px;
	    }
	}

	p {
		margin-top: 20px;
		margin-bottom: 0;
		line-height: 1.6;
	}

	.graph {
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
		opacity: 0.25;
	}
}

.level-about {
	padding: 70px 0 100px;

	h2 {
		margin-top: 0;
		margin-bottom: 0;
		font-weight: bold;
		font-size: 36px;
		line-height: 1.25;

	    @media screen and (max-width: 768px) {
			font-size: 28px;
	    }
	}
}

.level-experts {
	padding: 70px 0 75px;
	background: #777 url('/templates/administrative_director/images/experts.jpg') no-repeat center center;
	background-size: cover;
	color: #fff;

	h2 {
		margin-top: 0;
		margin-bottom: 20px;
		font-weight: bold;
		font-size: 36px;
		line-height: 1.25;
	}
}

.level-news {
	padding: 50px 0;
	background-color: #f8f8f8;

	h2 {
		margin-top: 0;
		margin-bottom: 35px;
		font-weight: 600;
		font-size: 36px;
		line-height: 1.25; // ~45px
	}
}

	.level-caption {
		.level-experts & {
			margin-top: 0;
			margin-bottom: 60px;
			max-width: 920px;
		}
	}

	.level-content {
		position: relative;
	}

	.level-more {
		margin-top: 40px;

		.level-experts & {
			margin-top: 70px;
		}
	}


// GALLERY
.swiper-button-prev-news,
.swiper-button-next-news,
.swiper-button-prev-experts,
.swiper-button-next-experts {
	overflow: hidden;
	position: absolute;
	left: auto;
	bottom: auto;
	width: 30px;
	height: 30px;
	border: none;
	border-radius: 50%;
	background: none;
	background-color: #78c479;
	background-repeat: no-repeat;
	text-indent: -9999px;
	opacity: 1;
	cursor: pointer;
}

.swiper-button-prev-news,
.swiper-button-prev-experts {
	right: 40px;
	background-image: url('/templates/administrative_director/images/arr-light-left.png');
	background-position: left 10px center;
}

.swiper-button-next-news,
.swiper-button-next-experts {
	right: 0;
	background-image: url('/templates/administrative_director/images/arr-light-right.png');
	background-position: right 10px center;
}

.swiper-button-prev-news,
.swiper-button-next-news {
	top: -77px;

	@media screen and (max-width: 640px) {
		top: -40px;
	}
}

.swiper-button-prev-experts,
.swiper-button-next-experts {
	top: -160px;

	@media screen and (max-width: 640px) {
		top: -40px;
	}
}


// FOOTER
.footer {
	margin-top: auto;
	padding: 35px 0;
	width: 100%;
	background-color: #2b2b2b;
	color: #fff;
	font-size: 14px;

	.container {
		@include clearfix();
	}
}

	.footer-copyright {
		margin-top: 30px;
		margin-bottom: 0;
	}
