@mixin clearfix {
	& {
		&:before,
		&:after {
			content: ' ';
			display: block;
		}
		&:after {
			clear: both;
		}
	}
}
